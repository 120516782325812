<template>
  <div>
    <div class="review">
      <div class="r_star flex-container">
        <h2>商品评价：</h2>
        <van-rate
          v-model="startValue"
          @change="changeStar"
          void-icon="star"
          void-color="#E3E3E3"
          color="#FF4848"
        />
        <span>{{ sratText[startValue - 1] }}</span>
      </div>

      <div class="r_textarea">
        <div class="r_textarea-wrap">
          <div class="flex-container">
            <img src="@image/goods/review_edit.png" />
            <textarea
              maxlength="250"
              placeholder="点击评价商品"
              v-model="reviewContext"
            ></textarea>
          </div>
          <div class="r_input">
            <ul class="r_input-ul flex-container">
              <li
                class="r_input-content"
                v-for="(item, index) in imglist"
                :key="index"
              >
                <img class="main" :src="item.url" v-if="item.type == 1" />
                <img
                  class="main"
                  :src="
                    `${item.url}?spm=qipa250&x-oss-process=video/snapshot,t_1000,f_jpg,w_500,h_500,m_fast`
                  "
                  v-else
                />
                <div class="del" @click="delImg(index)"></div>
              </li>

              <li class="r_input-li">
                <van-uploader
                  :before-read="beforeRead"
                  accept="image/*,video/*"
                  image-fit
                  max-count="9"
                >
                  <img src="@image/goods/review_inpuImg.png" />
                  <p>添加图片</p>
                </van-uploader>
              </li>
            </ul>
          </div>
        </div>

        <div class="r_save" v-if="reviewContext.length == 0">提交</div>
        <div class="r_save r_save_act" v-else @click="saveForm">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Rate, Uploader, Popup, Toast } from "vant";
import { VueCropper } from "vue-cropper";
import { post, form } from "@get/http";
import { mapMutations } from "vuex";
import EXIF from "exif-js";
export default {
  data() {
    return {
      uploadimg: "",
      sratText: ["非常差", "差", "一般", "好", "非常好"],
      startValue: 5,
      reviewContext: "",
      photoClip: {},
      imglist: [],
    };
  },
  components: {
    vanRate: Rate,
    vanUploader: Uploader,
    VueCropper,
    vanPopup: Popup,
  },
  methods: {
    ...mapMutations({
      fChangeReviewState: "fChangeReviewState",
    }),
    delImg(index) {
      this.imglist.splice(index, 1);
    },
    async beforeRead(val) {
      if (this.getMediaType(val) == "img") {
        let that = this;
        // let dataURL = this.getUrl(val);
        var reader = new FileReader();
        // 将文件加载进入
        reader.readAsDataURL(val);
        reader.onload = async function(e) {
          // 转换完成输出该文件base64编码
          let res = await post("/Memberevaluate/uploadDiscussStream", {
            data: { stream: this.result, store_id: 2 },
          });
          that.imglist.push({
            url: res,
            type: 1,
          });
        };
      } else {
        var formData = new window.FormData();
        formData.append("store_id", 2);
        formData.append("name", "review_video");
        formData.append(`review_video`, val);
        Toast.loading({
          message: "上传中...",
          forbidClick: true,
          duration: 0,
        });
        form("/Memberevaluate/uploadDiscussMedia", {
          data: formData,
        }).then((res) => {
          Toast("上传成功");
          this.imglist.push({
            url: res.result,
            type: 2,
          });
          // this.$route.go(-1);
        });
      }
    },
    changeStar(val) {
      // console.log(val);
    },
    async saveForm() {
      await post("/Memberevaluate/add", {
        data: {
          rec_id: this.$route.query.rec_id,
          scores: this.startValue,
          content: this.reviewContext,
          images: this.imglist,
          isanonymous: 0,
        },
      });
      Toast("评价成功");
      this.fChangeReviewState(true);
      this.$router.go(-1);
    },
    getMediaType(val) {
      if (val.type.indexOf("video/") == -1) {
        return "img";
      } else {
        return "video";
      }
    },

    getUrl(flie) {
      let url = "";
      if (window.createObjectURL != undefined) {
        // basic
        url = window.createObjectURL(flie);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(flie);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(flie);
      }
      return url;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
::v-deep {
  .van-uploader {
    display: block;
  }
  .van-uploader__wrapper {
    align-items: center;
    justify-content: center;
  }
}
.review {
  position: absolute;
  width: 100%;
  height: 100%;
}
.r_star {
  background: #fff;
  align-items: center;
  padding: rem(30) rem(45);
  // border-bottom: 1px solid #efefef;
  h2 {
    color: #333;
    font-size: rem(26);
    font-weight: bold;
  }
  span {
    color: #999;
    font-size: rem(24);
    margin-left: rem(27);
  }
}
.r_textarea {
  background: #fff;
  padding-bottom: rem(40);

  .r_textarea-wrap {
    border-radius: rem(16);
    background: #f9f9f8;
    width: rem(710);
    margin: 0 auto;
    padding: rem(27) rem(20);
    box-sizing: border-box;
  }

  img {
    width: rem(31);
    height: rem(30);
    margin-right: rem(18);
  }
}
textarea {
  width: rem(613);
  height: rem(250);
  font-size: rem(26);
  line-height: rem(36);
  color: #333333;
  border: none;
  resize: none;
  background: #f9f9f8;
  &::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #999;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #999;
  }

  &::-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #999;
  }
}

.r_input-ul {
  margin-top: rem(20);
  flex-wrap: wrap;
  .r_input-content,
  .r_input-li {
    margin-bottom: rem(20);
    &:nth-of-type(5n - 1) {
      margin-right: 0;
    }
    margin-right: rem(20);
    width: rem(150);
    height: rem(150);
    border-radius: rem(8);
  }
  .r_input-content {
    position: relative;
    .main {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: rem(8);
    }
    .del {
      width: rem(50);
      height: rem(50);
      position: absolute;
      top: rem(3);
      right: rem(0);
      background: url("~@image/goods/review_delimg.png") no-repeat rem(24)
        rem(2);
      background-size: rem(20) rem(20);
    }
  }
  .r_input-li {
    border: 2px dashed #ababab;
    box-sizing: border-box;
    position: relative;

    img {
      width: rem(34);
      height: rem(25);
      margin: rem(40) auto rem(10);
      display: block;
    }
    p {
      color: #888;
      font-size: rem(20);
      text-align: center;
    }
  }
}

.r_save {
  width: rem(659);
  height: rem(88);
  background: #eee;
  border-radius: rem(44);
  text-align: center;
  line-height: rem(88);
  margin: rem(40) auto 0;
  font-size: rem(28);
  color: #999;
}
.r_save_act {
  background: #ff4848;
  color: #fff;
}

::v-deep .van-uploader__input {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  display: block;
  width: rem(150);
  height: rem(150);
}

#clipArea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9998;
}

#clipBtn,
#cancel {
  width: rem(220);
  height: rem(75);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  // display: none;
  z-index: 9999;
  font-size: rem(28);
  text-align: center;
  line-height: rem(75);
  border-radius: rem(37.5);
  color: #fff;
}

#clipBtn {
  background: #ff4848;

  top: rem(1000);
}

#cancel {
  background: #000;
  top: rem(1100);
}
</style>
